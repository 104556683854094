import React from "react";
import FaIcon from "../../components/FaIcon";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Popover } from "antd";

// Define the type for each menu item
interface MenuItem {
  key: string;
  label: string;
  children?: MenuItem[]; // Nested menu items
  icon?: React.ReactNode;
  link: string;
}

// Define the props for the SidebarMenu component
interface SidebarMenuProps {
  items: MenuItem[];
  openKeys?: Set<string>; // Keys of initially opened menu items
  setOpenKeys: React.Dispatch<React.SetStateAction<Set<string>>>; // Optional callback to update openKeys
  selectedKey: string;
  collapsed: boolean;
}

const MenuComponent: React.FC<SidebarMenuProps> = ({
  items,
  openKeys = new Set(),
  setOpenKeys,
  selectedKey,
  collapsed,
}) => {
  const handleToggle = (key: string) => {
    setOpenKeys((prev) => {
      const newKeys = new Set(prev);
      if (newKeys.has(key)) {
        newKeys.delete(key);
      } else {
        newKeys.add(key);
      }
      return newKeys;
    });
  };

  const renderMenuItems = (menuItems: MenuItem[], hasParent: boolean): React.ReactNode => {
    return menuItems.map((item) => {
      const hasChildren = !!item.children;
      const isSelected = item.key === selectedKey;
      const isOpen = openKeys.has(item.key);

      if (!hasChildren) {
        if (hasParent) {
          return <MenuLink key={item.key} collapsed={collapsed} isSelected={isSelected} item={item} />;
        }
        return <MenuTopLink key={item.key} collapsed={collapsed} isSelected={isSelected} item={item} />;
      }

      if (collapsed) {
        return (
          <Popover
            key={item.key}
            content={
              <div className={"overflow-auto max-h-[600px] w-full"} data-cy={item.key}>
                {item.children?.map((child) => (
                  <MenuLink key={child.key} collapsed={false} isSelected={isSelected} item={child} />
                ))}
              </div>
            }
            placement="right"
            trigger="hover"
          >
            <div key={item.key}>
              <div
                className={classNames(
                  {
                    "bg-primary-light": isOpen,
                    "text-white": isOpen,
                  },
                  "flex justify-between items-center p-2 cursor-pointer group hover:bg-primary-light rounded-br-2xl rounded-tr-2xl"
                )}
                onClick={() => handleToggle(item.key)}
              >
                <div
                  className={classNames(
                    {
                      "text-white": isOpen,
                      "text-primary": !isOpen,
                      "parent-icon-white": isOpen,
                      "justify-center": collapsed,
                    },
                    "group-hover:text-white flex flex-row items-center gap w-full"
                  )}
                >
                  {item.icon}
                </div>
              </div>
              <div
                className={`transition-all ${isOpen ? "max-h-screen" : "max-h-0 overflow-hidden"} bg-transparentGray`}
              >
                {renderMenuItems(item.children!, true)}
              </div>
            </div>
          </Popover>
        );
      } else {
        return (
          <div key={item.key} data-cy={item.key}>
            <div
              className={classNames(
                {
                  "bg-primary-light": isOpen,
                  "text-white": isOpen,
                },
                "flex justify-between items-center p-2 cursor-pointer group hover:bg-primary-light rounded-br-2xl rounded-tr-2xl"
              )}
              onClick={() => handleToggle(item.key)}
            >
              <div
                className={classNames(
                  {
                    "text-white": isOpen,
                    "text-primary": !isOpen,
                    "justify-center": collapsed,
                    "parent-icon-white": isOpen,
                  },
                  "group-hover:text-white flex flex-row items-center gap w-full"
                )}
              >
                {item.icon}
                {item.label}
              </div>

              <>
                {isOpen ? (
                  <FaIcon
                    className={classNames("group-hover:text-white text-primary", { "text-white": isOpen })}
                    icon={faChevronLeft}
                  />
                ) : (
                  <FaIcon
                    className={classNames("group-hover:text-white text-primary", { "text-white": isOpen })}
                    icon={faChevronRight}
                  />
                )}
              </>
            </div>
            <div
              className={`transition-all ${isOpen ? "max-h-screen" : "max-h-0 overflow-hidden"} bg-transparentGray flex flex-col gap-1 mt-1`}
            >
              {renderMenuItems(item.children!, true)}
            </div>
          </div>
        );
      }
    });
  };

  return <div className="w-full menu flex flex-col gap-1">{renderMenuItems(items, false)}</div>;
};

interface MenuLinkProps {
  item: MenuItem;
  isSelected: boolean;
  collapsed: boolean;
}

function MenuLink(props: MenuLinkProps) {
  const { item, isSelected, collapsed } = props;
  const textColor = "text-primary";
  return (
    <Link key={item.key} to={item.link}>
      <div
        key={item.key}
        className={classNames(
          `group pl-4 p-2 gap flex row items-center cursor-pointer hover:bg-primary-lighter rounded-br-2xl rounded-tr-2xl w-full`,
          {
            "bg-primary-lighter": isSelected,
            "justify-center": collapsed,
          },
          textColor
        )}
        data-cy={item.key}
      >
        <div className={isSelected ? "parent-icon-white" : "group-hover:brightness-0 group-hover:invert"}>
          {item.icon}
        </div>
        {collapsed ? null : item.label}
      </div>
    </Link>
  );
}

function MenuTopLink(props: MenuLinkProps) {
  const { item, isSelected, collapsed } = props;
  const textColor = isSelected ? "text-white" : "text-primary";
  return (
    <Link key={item.key} to={item.link}>
      <div
        key={item.key}
        className={classNames(
          `group p-2 gap flex row items-center cursor-pointer hover:bg-primary-light rounded-br-2xl rounded-tr-2xl w-full`,
          {
            "bg-primary-light": isSelected,
            "justify-center": collapsed,
          },
          textColor
        )}
        data-cy={item.key}
      >
        <div className={isSelected ? "parent-icon-white" : "group-hover:brightness-0 group-hover:invert"}>
          {item.icon}
        </div>
        {collapsed ? null : item.label}
      </div>
    </Link>
  );
}

export default MenuComponent;
