import React, { ReactNode } from "react";
import BoxTitleWrapper from "./BoxTitle";
import { BoxWrapper } from "./box.style";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { useToggle, useUpdateEffect } from "react-use";
import BoxTitleExpandable from "./BoxTitleExpandable";

export default (props: {
  title?: ReactNode;
  footer?: ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
  icon?: IconProp;
  roundBottom?: boolean;
  roundTop?: boolean;
  className?: string;
  middle?: boolean;
  plain?: boolean;
  noShadow?: boolean;
  contentCenter?: boolean;
  customTitle?: ReactNode;
  titleExtra?: ReactNode;
  fullHeight?: boolean;
  underline?: boolean;
  topPadding?: boolean;
  tableMode?: boolean;
  hidden?: boolean;
  expandable?: boolean;
  open?: boolean;
}) => {
  const [open, toggleOpen] = useToggle(!props.expandable);

  useUpdateEffect(() => {
    toggleOpen(props.open);
  }, [props.open]);

  if (props.hidden) {
    return null;
  }
  const cls = classNames(
    "isoBoxWrapper",
    { "full-width": props.fullWidth },
    { "round-bottom": props.roundBottom },
    { "round-top": props.roundTop },
    { "middle": props.middle },
    { "plain": props.plain },
    { "full-height": props.fullHeight },
    { "content-center": props.contentCenter },
    { "underline-title": props.underline },
    { "top-padding": props.topPadding },
    { "table-mode": props.tableMode },
    { "expandable": props.expandable },
    [props.className]
  );
  return (
    <BoxWrapper className={cls}>
      {props.title && !props.expandable && (
        <BoxTitleWrapper
          extra={props.titleExtra}
          icon={props.icon}
          inner={props.roundBottom}
          middle={props.middle}
          plain={props.plain}
          subtitle={props.subtitle}
          title={props.title}
          underline={props.underline}
        />
      )}
      {props.title && props.expandable && (
        <BoxTitleExpandable
          expandable={props.expandable}
          extra={props.titleExtra}
          icon={props.icon}
          inner={props.roundBottom}
          middle={props.middle}
          open={open}
          plain={props.plain}
          subtitle={props.subtitle}
          title={props.title}
          toggleOpen={toggleOpen}
          underline={props.underline}
        />
      )}
      {open && props.customTitle && props.customTitle}
      {open && <div className={"box-content"}>{props.children}</div>}
      {props.footer && <div className={"box-footer"}>{props.footer}</div>}
    </BoxWrapper>
  );
};
