import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { TablePaginationConfig } from "antd/es/table";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { FormInstance } from "antd/es/form";
import { toJson } from "../helpers/apiHelpers";
import { ApiError, fetchEGate } from "../helpers/eGateApi";
import { apiErrorsToFieldErrors, joinIfArray, qsTypeDecoder } from "../helpers/utils";
import qs from "qs";
import { useHotkeys } from "react-hotkeys-hook";
import { PagedResultDto, PartialListDto } from "../eGate-API";
import useApp from "./useApp";
import { ReactNode } from "react";
import { isValueUsed as baseIsValueUsed } from "../helpers/api/UniquenesApi";
import { Modal, notification } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { TFunction } from "i18next";
import { responseErrorNotification } from "../helpers/responseErrorNotification";
import unionBy from "lodash-es/unionBy";
import isArray from "lodash-es/isArray";
import { useDebounce } from "use-debounce";
import { IdType } from "../env";

export interface EntityConfigBase {
  baseKey: string;
  key: string;
  keyDetail?: string;
  baseRoute: string;
}

export interface EntityConfig<TFilter = any> extends EntityConfigBase {
  endpoint?: string;
  listEndpoint?: string;
  getDetailEndpoint?: (id: IdType) => string;
  getEndpoint?: () => string;
  privateUrl?: boolean;
  initialData?: unknown;
  initialFilter?: TFilter;
  singularName: (t: TFunction) => string;
  name: (t: TFunction) => string;
  roleView: string[];
  roleAdmin: string[];
  noIdInUrl?: boolean;
  noIdInCall?: boolean;
  noRedirect?: boolean;
  syncFilterWithURL?: boolean;
  filterFormatter?: (filter: TFilter) => TFilter;
  shouldFetch?: (filter: TFilter) => boolean;
  icon?: ReactNode;
  activateIcon?: ReactNode;
  activateText?: ReactNode;
  sendCulture?: boolean;
  disableCache?: boolean;
  tabCountForm?: number;
  tabCountView?: number;
  dependencies?: QueryKey; //for invalidation
  normalizer?: (raw: any, oldData: any, oldFilter: any) => any;
  infiniteNormalizer?: (raw: PartialListDto<any>, t: TFunction) => PartialListDto<any>;
  keepPreviousData?: boolean;
  optimistic?: boolean;
  disableHotKeys?: boolean;
  syncCustomerFilter?: boolean;
  noDetail?: boolean;
  goToDetail?: (id?: IdType) => void;
  onlyWithFeature?: string;
}

export interface BaseDataType {
  id?: IdType;
  rowCount?: number;
  data?: any;
}

/**
 * Sort order options
 */
type SortOrder = "ascend" | "descend" | undefined;

/**
 * Pagination data
 */
export interface BaseFilter {
  /** Current page */
  page?: number;
  /** Page size */
  pageSize?: number;
  /** Current sort order */
  sortOrder?: SortOrder;
  /** Name of the field the data are sorted by */
  sortBy?: string;
  /** Optional full-text field */
  fulltextfield?: string;
  /** Fulltext search term */
  fulltext?: string;
  /**
   * Indicator that custom filter is used, it is set to true after the url filter is modified
   */
  f?: boolean;
  /**
   * tab
   */
  t?: boolean;
}

/**
 * Use simple fetch query
 *
 * @param key      - Query key
 * @param endpoint - Endpoint to get the data from
 * @param query    - HTTP query parameters
 *
 * @returns React-query for given endpoint
 */
export function useFetch<T>(key: string, endpoint: string, query?: any, filter?: any) {
  return useQuery({
    queryKey: [key, filter],
    queryFn: () => toJson<T>(fetchEGate("GET", endpoint, undefined, query)),
  });
}

export function entityFactory(key: string): EntityConfigBase {
  return {
    baseKey: key,
    key: key + "_table",
    keyDetail: key + "_detail",
    baseRoute: key,
  };
}

/**
 * When entity used only for example tabs
 */
export const defaultEntityConfig = {
  key: "",
  baseKey: "",
  endpoint: undefined,
  roleView: [""],
  roleAdmin: [""],
  singularName: () => "",
  name: () => "",
  baseRoute: "",
};

interface UseCreateOneParams {
  customEndpoint?: string;
  apiVersion?: string;
  preventNotification?: boolean;
}

interface UseUpdateOneParams {
  customEndpoint?: string;
  apiVersion?: string;
  preventNotification?: boolean;
}

/**
 * Function that constructs functions
 * for entity react-query manipulation
 *
 * @param config - Entity configuration
 *
 * @returns Object with query functions
 */
export default function useEntity<T extends BaseDataType, TFilter = BaseFilter>(config: EntityConfig) {
  const queryClient = useQueryClient();
  const location = useLocation();
  function getQueryConf(enabled = true, initialData?: any) {
    return {
      enabled,
      refetchOnWindowFocus: config.disableCache,
      placeholderData: initialData,
      keepPreviousData: config.keepPreviousData,
    };
  }

  const navigate = useNavigate();
  const params = useParams();
  const { appData } = useApp();
  const selectedCustomer = appData?.selectedCustomer;
  const selectedCustomerId = selectedCustomer?.id;

  const { t, i18n } = useTranslation();

  function getUrlFilter(): BaseFilter & Partial<TFilter> {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
      decoder: qsTypeDecoder(),
    }) as BaseFilter & Partial<TFilter>;
  }

  function getDetailEndpoint(id: IdType) {
    if (config.getDetailEndpoint) {
      return config.getDetailEndpoint(id);
    } else {
      return `${config.endpoint}/${id}`;
    }
  }

  /**
   * Gets query object for entity detail
   *
   * @param id             - Entity Id
   * @param enabled        - Is query enabled by default
   * @param apiVersion     - Optional API version - default = v1
   * @returns Query object as returned from @see useQuery
   */
  function useEntityDetailFetch(id: IdType, enabled = true, apiVersion?: string) {
    if (!config.keyDetail) {
      throw new Error("No key detail");
    }
    const endpoint = getDetailEndpoint(id);
    function dataFn() {
      if (id && id != 0) {
        const queryParams = config.sendCulture ? { culture: i18n.language } : {};
        const paramsEmpty = Object.keys(queryParams).length === 0;
        return toJson<T>(
          fetchEGate("GET", endpoint, undefined, paramsEmpty ? undefined : queryParams, {
            version: apiVersion,
            privateUrl: config.privateUrl,
          }).catch((reason) => {
            if (reason.status === 401) {
              navigate("/signin", { replace: true, state: location.pathname });
            }
            return Promise.reject(reason);
          })
        );
      }
      return Promise.resolve({} as unknown as undefined);
    }

    return useQuery<T | undefined, ApiError>({
      queryKey: [config.keyDetail, id],
      queryFn: () => dataFn(),
      ...getQueryConf(id != 0 && enabled),
    });
  }

  function useEntityFormFetch(id: IdType) {
    return useEntityDetailFetch(id, id != 0);
  }
  function getFilter(urlFilter?: BaseFilter & Partial<TFilter>) {
    if (!urlFilter?.f) {
      return {
        ...config.initialFilter,
        ...urlFilter,
      };
    }

    return urlFilter;
  }
  function useEntityFilter() {
    const urlFilter: BaseFilter & Partial<TFilter> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      decoder: qsTypeDecoder(),
    }) as BaseFilter & Partial<TFilter>;

    const filter = getFilter(urlFilter);

    /**
     * Callback for setting the newFilter
     * @param newFilterParam
     * @param userChanged the filter was changed by user
     */
    function setFilter(newFilterParam: Partial<TFilter> | Partial<BaseFilter>, userChanged = true) {
      const newPage = filter?.page === (newFilterParam as BaseFilter)?.page ? 1 : (newFilterParam as BaseFilter)?.page;
      const newFilter = { ...newFilterParam, page: newPage };

      navigate(
        `${location.pathname}?${qs.stringify({
          ...newFilter,
          f: userChanged,
          t: getTab(),
        })}`,
        { replace: true }
      );
    }

    /**
     * Callback on table page or sort change
     */
    function onTableChange(
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[]
    ) {
      const singleSorter = sorter as SorterResult<T>;
      const tempSortBy = joinIfArray(singleSorter.field as never, ".");
      const tempOrder = singleSorter.order as SortOrder;

      function getCurrentPage() {
        if (tempOrder !== undefined && (filter?.sortBy !== tempSortBy || tempOrder !== filter?.sortOrder)) {
          // If sorting changed, jump to first page
          return 1;
        }

        return pagination.current || 1;
      }

      setFilter({
        ...filter,
        page: getCurrentPage() as number,
        sortBy: tempOrder ? tempSortBy : undefined,
        sortOrder: tempOrder,
        pageSize: parseInt(pagination.pageSize as unknown as string, 10),
      });
    }

    function nextPage() {
      setFilter({
        ...filter,
        page: filter?.page ? (filter?.page as number) + 1 : 1,
      });
    }

    function prevPage() {
      if (filter?.page !== 1) {
        setFilter({
          ...(filter ?? {}),
          page: (filter?.page ?? 2) - 1,
        });
      }
    }

    function columnSorter(key: string) {
      return {
        sorter: true,
        sortOrder: filter?.sortBy === key ? filter?.sortOrder : undefined,
      };
    }

    function onSearch(fulltext: string) {
      if (filter) {
        setFilter({ ...filter, fulltext });
      }
    }

    function setFilterByKey(field: keyof TFilter, value: unknown) {
      if (filter) {
        const newFilter = {
          ...filter,
          [field]: value,
        };
        setFilter(newFilter);
      }
    }

    return {
      setFilter,
      setFilterByKey,
      onTableChange,
      columnSorter,
      onSearch,
      nextPage,
      prevPage,
      filter,
    };
  }

  function setData(data: T) {
    queryClient.setQueryData([config.key], data);
  }

  function setDetailData(data: T) {
    queryClient.setQueryData([config.keyDetail, data.id], data);
  }

  function getData() {
    const queryKeys = queryClient
      .getQueryCache()
      .getAll()
      .map((query) => query.queryKey);
    const queryKeysFilered = queryKeys.filter((key) => key.includes(config.key));
    const queryKey = queryKeysFilered[queryKeysFilered.length - 2];
    return queryClient.getQueryData(queryKey as string[]);
  }

  function getPagedData(filter?: TFilter, privateUrl?: boolean, ac?: AbortController) {
    function formatFilter() {
      function addCulture() {
        if (config.sendCulture) {
          return {
            ...filter,
            culture: i18n.language,
          };
        }
        return filter;
      }
      const f = addCulture();
      if (config.filterFormatter && f) {
        return config.filterFormatter(f);
      }
      return f;
    }

    return toJson<T>(
      fetchEGate("GET", config.endpoint + "/", undefined, formatFilter(), {
        privateUrl,
        ac,
      }).catch((reason) => {
        if (reason.status === 401) {
          navigate("/signin", { replace: true, state: location.pathname });
        }
      })
    ).then((res) => {
      if (config.normalizer) {
        return config.normalizer(res, getData(), filter);
      }
      return res;
    });
  }

  /**
   * Get the key filter for the current filter
   * @param filter
   */
  function getKeyFilter(filter: any) {
    const { t: __, f, ...restFilter } = (filter as BaseFilter) ?? ({} as BaseFilter);

    if (!f) {
      if (selectedCustomer) {
        return {
          ...restFilter,
          ...config.initialFilter,
          selectedCustomer,
        };
      }
      if (Object.keys(restFilter).length === 0) {
        return undefined;
      }
      return { ...restFilter, ...config.initialFilter };
    }

    if (selectedCustomer) {
      return {
        ...restFilter,
        selectedCustomer,
      };
    }
    if (Object.keys(restFilter).length === 0) {
      return undefined;
    }
    return restFilter;
  }

  function useEntityFetch({ key, privateUrl }: EntityConfig, filter?: TFilter, enabled = true) {
    function get(): any {
      function getRequestFilter(): any {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { t: __, f } = (filter as BaseFilter) ?? ({} as BaseFilter);

        if (!f) {
          if (selectedCustomerId) {
            return {
              customerId: selectedCustomerId,

              ...config.initialFilter,
              ...filter,
            };
          }
          return { ...config.initialFilter, ...filter };
        }
        if (selectedCustomerId) {
          return {
            customerId: selectedCustomerId,
            ...filter,
          };
        }
        return filter;
      }
      if ((config.shouldFetch !== undefined && !config?.shouldFetch?.(filter)) || !enabled) {
        return getData();
      }

      return getPagedData(getRequestFilter(), privateUrl);
    }
    const [debouncedFilter] = useDebounce(filter, 500, {
      leading: true,
      equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
    });

    return useQuery<T & BaseDataType, ApiError>({
      queryKey: [key, getKeyFilter(debouncedFilter)],
      queryFn: get,
      ...getQueryConf(enabled, config.initialData),
    });
  }

  function useEntityInfiniteFetch(filter?: TFilter) {
    return useQuery({
      queryKey: [config.key + "_list", filter],
      queryFn: () => infiniteQuery(filter),
    });
  }

  function invalidateDependencies() {
    if (config.dependencies) {
      for (const dependency of config.dependencies as string[]) {
        invalidateEntity(dependency);
      }
    }
  }

  function invalidateEntity(key: string) {
    void queryClient.invalidateQueries({
      predicate: (query) => {
        return (query.queryKey[0] as string)?.indexOf(key) != -1;
      },
    });
  }

  function useDeleteOne() {
    const func = (id: string | number) => fetchEGate("DELETE", `${config.endpoint}/${id}`);
    return useMutation({
      mutationFn: func,
      onSettled: () => {
        invalidateEntity(config.baseKey);
        invalidateDependencies();
      },
      onError: (err, newTodo, context: any) => {
        Modal.error({
          content: <div>{config.singularName(t) + t(" deletion cannot be completed.")}</div>,
        });

        const filterKey = getKeyFilter(getUrlFilter());

        queryClient.setQueryData([config.key, getKeyFilter(filterKey)], context.prev);
      },
      onMutate: (id) => {
        const filterKey = getKeyFilter(getUrlFilter());

        void queryClient.cancelQueries({
          queryKey: [config.keyDetail],
        });
        void queryClient.cancelQueries({
          queryKey: [config.key, filterKey],
        });

        const queryKey = [config.key, filterKey].filter((i) => i);
        const prev: PagedResultDto<T> | undefined = queryClient.getQueryData(queryKey);
        if (prev) {
          queryClient.setQueryData(queryKey, (old: any) => ({
            ...old,
            results: [...old.results].filter((o) => o.id !== id),
          }));
        }

        return { prev };
      },
    });
  }

  function useActivateOne() {
    const func = (id: string | number) => fetchEGate("PUT", `${config.endpoint}/${id}/activate`);
    return useMutation({
      mutationFn: func,
      onSettled: () => invalidateEntity(config.baseKey),
    });
  }

  function useUpdateOne<TUpdate extends BaseDataType>(form?: FormInstance, updateOneParams?: UseUpdateOneParams) {
    function getEndpoint(id: IdType) {
      if (config.getDetailEndpoint) {
        return config.getDetailEndpoint(id);
      }
      if (updateOneParams?.customEndpoint) {
        return updateOneParams.customEndpoint;
      }
      return config.endpoint as string;
    }
    function getUrl(id?: IdType) {
      const endpoint = getEndpoint(id as IdType);
      if (config.noIdInCall) {
        return endpoint;
      } else if (id) {
        return `${endpoint}/${id as string}`;
      }
      return endpoint;
    }

    const func = (data: TUpdate) => {
      if (!config.endpoint) {
        return Promise.reject("No endpoint");
      }
      return toJson<TUpdate>(
        fetchEGate("PUT", getUrl(data.id) as string, data, undefined, {
          showNotification: false,
          version: updateOneParams?.apiVersion,
        })
      );
    };

    return useMutation({
      mutationFn: func,
      onMutate: (data: TUpdate) => {
        if (config.optimistic) {
          void queryClient.cancelQueries({
            queryKey: [config.keyDetail],
          });
          void queryClient.cancelQueries({
            queryKey: [config.key],
          });

          const query = queryClient.getQueryCache().find({
            queryKey: [config.key],
          });
          if (!query) {
            return;
          }
          const prev: T | undefined = queryClient.getQueryData(query?.queryKey);

          if (prev) {
            const newEnt = {
              ...prev,
              ...data,
            };
            queryClient.setQueryData(query?.queryKey, newEnt);
          }

          return { prev };
        }
      },
      onSuccess: (data: TUpdate) => {
        if (!config.noRedirect) {
          if (data?.id && !config.noIdInUrl) {
            navigate(`/app/${config?.baseRoute}/${data?.id as string}`);
          } else if (config.noIdInUrl) {
            navigate(`/app/${config?.baseRoute}`);
          }
        }
        if (!updateOneParams?.preventNotification) {
          notification.success({
            message: `${t("Update successful")}`,
            placement: "top",
          });
        }

        if (!config.optimistic) {
          invalidateEntity(config.baseKey);
          invalidateDependencies();
        } else {
          // @ts-ignore
          setDetailData(data);
          invalidateDependencies();
        }
      },
      onError: (error: ApiError) => {
        responseErrorNotification(error, t);

        form?.setFields(apiErrorsToFieldErrors(error));
      },
    });
  }

  function useCreateOne(form?: FormInstance, createOneParams?: UseCreateOneParams | undefined) {
    const func = (data: any) => {
      if (!config.endpoint) {
        return Promise.reject("No endpoint");
      }
      function getEndpoint() {
        if (createOneParams?.customEndpoint) {
          return createOneParams?.customEndpoint as string;
        }
        return config.endpoint as string;
      }
      return toJson<T>(
        fetchEGate("POST", getEndpoint(), data, undefined, {
          showNotification: false,
          version: createOneParams?.apiVersion,
        })
      );
    };

    return useMutation({
      mutationFn: func,
      onSuccess: (data: T) => {
        if (!config.noRedirect) {
          if (data?.id) {
            navigate(`/app/${config.baseRoute}/${data?.id as string}`);
          }
        }
        if (!createOneParams?.preventNotification) {
          notification.success({
            message: t("Create successful"),
            placement: "top",
          });
        }

        invalidateDependencies();
        invalidateEntity(config.baseKey);
      },
      onError: (error: ApiError) => {
        responseErrorNotification(error, t);
        responseErrorNotification;
        form?.setFields(apiErrorsToFieldErrors(error));
      },
    });
  }

  function getExportUrl() {
    return config.endpoint + "/export.csv";
  }

  function goHome() {
    navigate(`/app/${config.baseRoute}`);
  }

  function goToEdit(id?: IdType) {
    const tabQs = getTabQueryString();
    if (id) {
      navigate(`/app/${config.baseRoute}/${id}/edit${tabQs}`);
    } else {
      navigate(`/app/${config.baseRoute}/edit${tabQs}`);
    }
  }

  function goToNew() {
    navigate(`/app/${config.baseRoute}/0/edit`);
  }

  function goToDetail(id: IdType) {
    if (config.goToDetail) {
      config.goToDetail(id);
      return;
    }
    if (config.noDetail) return;
    // empty url
    const url = new URL("https://e.e");

    const newUrl = "/app/" + config.baseRoute;
    const currentTab = getTab();
    if (currentTab) {
      url.searchParams.set("t", currentTab);
    }
    url.searchParams.set("returnTo", newUrl + url.search);

    navigate(`/app/${config.baseRoute}/${id}/${url.search}`);
  }

  function setActiveTab(key: string) {
    if (key === "edit") {
      return;
    }
    navigate(`${location.pathname}?t=${key}`);
  }

  function getTab() {
    const tabParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      decoder: qsTypeDecoder(),
    });
    return tabParams.t as string;
  }

  function getTabQueryString() {
    const tab = getTab();
    if (tab) {
      return `?t=${tab}`;
    }
    return "";
  }

  function getActiveTab(defaultTab = "1"): string {
    const tab = getTab();
    if (tab) {
      return `${tab}`;
    }
    return defaultTab;
  }

  function isEdit() {
    return location.pathname.includes("edit");
  }

  useHotkeys("f2", () => {
    if (config.disableHotKeys) return;
    // @ts-ignore
    const id = params.id;
    if (id) {
      if (isEdit()) {
        goToDetail(id);
      } else {
        goToEdit(id);
      }
    }
  });

  useHotkeys("r", () => {
    if (config.disableHotKeys) return;

    invalidateEntity(config.baseKey);
  });

  /**
   * Gets title for entity detail view
   * @param isNew - Indicates new entity
   * @param title - Optional override for the title
   * @returns Title to show on the form
   */
  function getFormTitle(isNew: boolean, title?: string) {
    return isNew ? `${t("New")} ${config.singularName(t)}` : `${title ?? config.singularName(t)}`;
  }

  function addInitial(value: T[], initial: T): T[] {
    if (initial) {
      if (isArray(initial)) {
        return unionBy(initial as unknown as any[], value, "id");
      } else {
        return unionBy([initial], value, "id");
      }
    }
    return value;
  }

  async function infiniteQuery(filter: any, initialValue?: any, ac?: AbortController): Promise<PartialListDto<T>> {
    if (!config.endpoint) {
      return Promise.reject("No endpoint");
    }
    function getListEndpoint() {
      if (config.listEndpoint) {
        return config.listEndpoint;
      }
      return config.endpoint + "/list";
    }
    const response = fetchEGate(
      "GET",
      `${getListEndpoint()}`,
      null,
      {
        ...filter,
      },
      { ac }
    );
    const json = await toJson<PartialListDto<T>>(response);

    function getResponseData() {
      if (config.infiniteNormalizer) {
        return config.infiniteNormalizer(json, t);
      }
      return json;
    }
    const responseData = getResponseData();
    if (initialValue) {
      const initialLength = isArray(initialValue) ? initialValue.length : 1;

      return {
        results: addInitial(responseData.results, initialValue),
        first: 1,
        rowCount: initialValue ? responseData.rowCount + initialLength : responseData.rowCount,
      };
    }
    return responseData;
  }

  async function isValueUsed(
    name: string,
    value: any,
    fieldTitle?: string,
    message?: string,
    scope?: Record<keyof any, unknown>
  ) {
    if (!config.endpoint) {
      return undefined;
    }
    return baseIsValueUsed(
      config.endpoint as string,
      name,
      value,
      message ? message : `${fieldTitle} ${t("has already been used")}`,
      scope
    );
  }

  /**
   * Gets an entity
   * @param id
   */
  async function getOne(id: number): Promise<T> {
    return toJson<T>(fetchEGate("GET", `${config.endpoint}/${id}`));
  }

  function getDetailLink(id: IdType) {
    return `/app/${config.baseRoute}/${id}`;
  }

  return {
    useEntityFetch: (filter?: (TFilter & BaseFilter) | undefined, enabled?: boolean) =>
      useEntityFetch(config, filter, enabled),
    useEntityDetailFetch: (id: IdType) => useEntityDetailFetch(id),
    useEntityFormFetch: (id: IdType) => useEntityFormFetch(id),
    useEntityInfiniteFetch,
    useEntityFilter: () => useEntityFilter(),
    useEntityDeleteOne: useDeleteOne,
    useEntityActivateOne: useActivateOne,
    useEntityCreateOne: useCreateOne,
    useEntityUpdateOne: useUpdateOne,
    goToEdit: (id?: IdType) => goToEdit(id),
    goToNew: () => goToNew(),
    goToDetail: (id: IdType) => goToDetail(id),
    goHome: () => goHome(),
    setData: (data: T) => setData(data),
    invalidateEntity: () => invalidateEntity(config.baseKey),
    setActiveTab,
    getActiveTab,
    getExportUrl,
    getFormTitle,
    infiniteQuery,
    isValueUsed,
    setDetailData,
    getOne,
    getPagedData,
    getDetailLink,
  };
}
